.image-bg {
    position: relative;
    width: 100%;
    height: 500px;
    background-position: top;
    background-attachment: scroll;
    background-size: cover;
    width: 100%;
}
.image-left {
    background-image: url(../images/hero-0.png);
}
.form-left {
    background-image: url(../images/hero-5.png);
}
.image-right {
    background-image: url(../images/hero-2.png);
}
.left-bg {
    position: relative;
    width: 100%;
    height: 500px;
    background: rgba(0, 0, 0, 0.6);
    background: linear-gradient(110deg, rgba(23, 45, 78, 1) 5%, rgba(0, 0, 0, 0.6) 40%)
}
.right-bg {
    position: relative;
    width: 100%;
    height: 500px;
    background: rgba(0, 0, 0, 0.6);
    background: linear-gradient(-110deg, rgba(23, 45, 78, 1) 5%, rgba(0, 0, 0, 0.6) 40%)
}

.left-info, .right-info {
    position: absolute;
    height: 100%;
    width: 100%;
}

.left-info {
    margin-top: 40px;
}

.right-info {
    margin-top: 15px;
    right: 0px;
}

.info-title-ctr {
    text-align: center;
    color: white;
    text-shadow: 0px 2px 4px black;
    margin-bottom: 30px;
}

.info-title-ctr h1 {
    font-size: 28px;
    font-weight: 400;
}

.info-text-ctr h3 {
    width: 90%;
    max-width: 300px;
    margin: auto;
    color: white;
    text-shadow: 0px 2px 4px black;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
}

.info-text-ctr h6 {
    text-align: center;
    font-size: 14px;
    margin: 0px;
    margin-top: 40px;
    color: white;
    text-shadow: 0px 2px 4px black;
}

.info-select-ctr {
    margin: auto;
    margin-top: 60px;
    text-align: center;
    width: 50%;
    max-width: 500px;
}

.info-select-ctr select {
    border: 2px solid white;
    width: 100%;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.4);
    color: white;
}

.info-select-ctr option {
    background: white;
    font-size: 16px;
    color: black;
    border: none;
}

.info-btn-ctr {
    margin-top: 60px;
    text-align: center;
}

.info-btn-ctr button {
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background: #2B5695;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    transition: 300ms;
}

.info-btn-ctr button:hover {
    cursor: pointer;
    background: #3464ac;
}

.info-btn-ctr button:disabled {
    background: rgba(0, 0, 0, 0);
    border: 1px solid white;
    color: white;
    opacity: 0.3;
    box-shadow: none;
    transition: 300ms;
}

.info-btn-ctr button:disabled:hover {
    cursor: default;
}

.info-btn {
    margin: 0px 10px;
}

.popup-bg {
    position: fixed;
    z-index: 20000;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.popup-ctr {
    position: fixed;
    z-index: 21000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 600px;
    height: 80%;
    max-height: 800px;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 2px 4px black;
}

.popup-exit {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    opacity: 0.5;
}

.popup-exit img {
    width: 100%;
    height: 100%;
}

.popup-exit:hover {
    cursor: pointer;
    opacity: 1;
}

@media (min-width: 1200px) {
    .image-bg {
        position: relative;
        width: 100%;
        height: 800px;
        background-position: top;
        background-attachment: fixed;
        background-size: cover;
        width: 100%;
    }
    .left-bg {
        position: relative;
        width: 100%;
        height: 800px;
        background: rgba(0, 0, 0, 0.6);
        background: linear-gradient(110deg, rgba(23, 45, 78, 1) 20%, rgba(0, 0, 0, 0.6) 40%)
    }
    .right-bg {
        position: relative;
        width: 100%;
        height: 800px;
        background: rgba(0, 0, 0, 0.6);
        background: linear-gradient(-110deg, rgba(23, 45, 78, 1) 20%, rgba(0, 0, 0, 0.6) 40%)
    }

    .left-info, .right-info {
        position: absolute;
        height: 65%;
        width: 40%;
    }

    .left-info {
        margin-top: 120px;
    }

    .right-info {
        margin-top: 60px;
        right: 0px;
    }

    .info-title-ctr {
        text-align: center;
        color: white;
        text-shadow: 0px 2px 4px black;
        margin-bottom: 50px;
    }

    .info-title-ctr h1 {
        font-size: 42px;
        font-weight: 400;
    }

    .info-text-ctr h3 {
        margin: auto;
        color: white;
        max-width: 400px;
        text-shadow: 0px 2px 4px black;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.8;
    }

    .info-text-ctr h6 {
        text-align: center;
        font-size: 14px;
        margin: 0px;
        margin-top: 40px;
        color: white;
        text-shadow: 0px 2px 4px black;
    }

    .info-select-ctr {
        margin: auto;
        margin-top: 60px;
        text-align: center;
        width: 50%;
        max-width: 500px;
    }

    .info-select-ctr select {
        border: 2px solid white;
        width: 100%;
        border-radius: 10px;
        font-size: 20px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.4);
        color: white;
    }

    .info-select-ctr option {
        background: white;
        font-size: 16px;
        color: black;
        border: none;
    }

    .info-btn-ctr {
        margin-top: 60px;
        text-align: center;
    }

    .info-btn-ctr button {
        font-size: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 6px;
        background: #2B5695;
        color: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
        transition: 300ms;
    }

    .info-btn-ctr button:hover {
        cursor: pointer;
        background: #3464ac;
    }

    .info-btn-ctr button:disabled {
        background: rgba(0, 0, 0, 0);
        border: 1px solid white;
        color: white;
        opacity: 0.3;
        box-shadow: none;
        transition: 300ms;
    }

    .info-btn-ctr button:disabled:hover {
        cursor: default;
    }

    .info-btn {
        margin: 0px 10px;
    }

    .popup-bg {
        position: fixed;
        z-index: 20000;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
    }

    .popup-ctr {
        position: fixed;
        z-index: 21000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        max-width: 600px;
        height: 80%;
        max-height: 800px;
        background: white;
        border-radius: 6px;
        box-shadow: 0px 2px 4px black;
    }

    .popup-exit {
        position: fixed;
        top: 10px;
        right: 10px;
        width: 35px;
        height: 35px;
        opacity: 0.5;
    }

    .popup-exit img {
        width: 100%;
        height: 100%;
    }

    .popup-exit:hover {
        cursor: pointer;
        opacity: 1;
    }
}
