.page-ctr {
    position: relative;
    margin: auto;
    width: 90%;
    max-width: 1000px;
}

.page-img-ctr {
    position: relative;
    width: 100%;
    margin: 60px auto;
}

.page-img {
    position: relative;
    width: 160px;
    margin: auto;
    margin-bottom: 20px;
}

.page-img img {
    width: 100%;
    border-radius: 250px;
    text-align: center;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); */
    box-shadow: none;
}

.img-text {
    text-align: center;
}

.img-text h5, h6 {
    margin: 6px;
}

.img-text h5 {
    font-size: 21px;
    font-weight: 300;
}
.img-text h6 {
    font-size: 10px;
    font-weight: 200;
}

.page-text h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 400;
}

.page-text p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}

.page-text {
    margin: 20px auto 80px;
}

@media (min-width: 1200px) {
    .page-ctr {
        position: relative;
        margin: auto;
        max-width: 1000px;
    }
    
    .page-img-ctr {
        position: relative;
        width: 100%;
        margin: 100px auto;
    }
    
    .page-img {
        position: relative;
        width: 280px;
        margin: auto;
        margin-bottom: 20px;
    }
    
    .page-img img {
        width: 100%;
        border-radius: 250px;
        text-align: center;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 1);
    }
    
    .img-text {
        text-align: center;
    }
    
    .img-text h5, h6 {
        margin: 10px;
    }
    
    .img-text h5 {
        font-size: 28px;
        font-weight: 300;
    }
    .img-text h6 {
        font-size: 13px;
        font-weight: 400;
    }
    
    .page-text h2 {
        font-size: 32px;
        text-align: center;
        margin-bottom: 60px;
        font-weight: 400;
    }
    
    .page-text p {
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;
    }
    
    .page-text {
        margin: 20px auto 200px;
    }
}