.navbar-ctr {
    position: fixed;
    z-index: 20000;
    background: #fcfcfc;
    width: 100%;
    height: 74px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    transition: 200ms;
}

a {
    text-decoration: none;
    color: black;
}

.navbar-links {
    display: none;
    position: fixed;
    right: 40px;
    height: 160px;
}

.mobile-menu {
    display: block;
    background: white;
    position: fixed;
    top: 0px;
    height: 100%;
    width: 300px;
}

.menu-bg-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%;
}

.menu-open {
    right: 0px;
    transition: 300ms;
}

.menu-bg-open {
    opacity: 1;
    transition: 300ms;
}

.menu-bg-closed {
    opacity: 0;
    pointer-events: none;
    transition: 300ms;
}

.menu-closed {
    right: -500px;
    transition: 200ms;
}

.mobile-menu .navbar-item {
    color: #222;
    position: relative;
    width: 100%;
    padding: 24px 20px;
    margin-top: 6px;
    top: 40px;
}

.mobile-menu a {
    position: relative;
    width: 100%;
    font-size: 20px;
    top: 200px;
}

.navbar-item {
    display: inline-block;
    padding: 15px;
    color: #555;
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
}

.navbar-item:hover {
    cursor: pointer;
    color: #2B5695;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.mobile-menu .navbar-item:hover {
    background: #3464ac;
    color: white;
}

.menu-btn-ctr {
    position: fixed;
    right: 0px;
    top: 0%;
    transform: translate(-10%, 25%);
    z-index: 10000;
    width: 59px;
    height: 51px;
    transition: 200ms;
}

.menu-btn-ctr:hover {
    cursor: pointer;
}

.menu-line-1,
.menu-line-2,
.menu-line-3 {
    background: #000;
    height: 2px;
    width: 27px;
}

.menu-lines-closed {
    position: relative;
    top: 8px;
}

.menu-lines-closed > * {
    margin: 7px auto;
}

.menu-lines-open {
    position: relative;
    top: 22px;
}

.menu-lines-open > * {
    margin: auto;
}

.menu-lines-open .menu-line-1 {
    position: relative;
    top: 2px;
    transform: rotate(45deg);
}
.menu-lines-open .menu-line-2 {
    transform: rotate(-45deg);
}

.phone-icon {
    display: none;
}

.mobile-phone-icon {
    display: block;
    position: fixed;
    left: 10px;
    width: 36px;
    height: 36px;
    top: 0%;
    transform: translate(0%, 57%);
    transition: 200ms;
}

.mobile-phone-icon img {
    width: 100%;
    height: 100%;
}

.mobile-menu .active {
    background: #2B5695;
    color: white;
}

@media (min-width: 400px) {
    .navbar-ctr {
        position: fixed;
        z-index: 20000;
        background: #fcfcfc;
        width: 100%;
        height: 95px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
        transition: 200ms;
    }

    .mobile-phone-icon {
        display: block;
        position: fixed;
        left: 10px;
        width: 36px;
        height: 36px;
        top: 0%;
        transform: translate(0%, 85%);
        transition: 200ms;
    }
    
    .mobile-phone-icon img {
        width: 100%;
        height: 100%;
        transition: 200ms;
    }

    .menu-btn-ctr {
        position: fixed;
        right: 0px;
        top: 0%;
        transform: translate(-10%, 45%);
        z-index: 10000;
        width: 59px;
        height: 51px;
        transition: 200ms;
    }
}

@media (min-width: 1200px) {
    .mobile-menu {
        display: none;
    }

    .navbar-links {
        display: block;
    }
    .menu-btn-ctr {
        display: none;
    }

    .phone-icon {
        display: block;
    }
    
    .mobile-phone-icon {
        display: none;
    }

    .navbar-ctr {
        position: fixed;
        z-index: 20000;
        background: #fcfcfc;
        width: 100%;
        height: 160px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
        transition: 200ms;
    }
    
    .navbar-links {
        position: fixed;
        right: 40px;
        height: 160px;
        transition: 200ms;
    }
    
    .navbar-item {
        display: inline-block;
        padding: 15px;
        color: #555;
        margin: 0px 5px;
        font-weight: 600;
    }

    .active {
        color: #2079ff;
    }
    
    .navbar-item:hover {
        cursor: pointer;
        color: #2079ff;
        box-shadow: none;
    }
    
    .menu-btn-ctr {
        position: fixed;
        right: 0px;
        top: 0px;
        z-index: 10000;
        width: 59px;
        height: 51px;
        background: #eee;
    }
    
    .menu-btn-ctr:hover {
        cursor: pointer;
        background: #ddd;
    }
    
    .menu-line-1,
    .menu-line-2,
    .menu-line-3 {
        background: #333;
        height: 2px;
        width: 31px;
    }
    
    .menu-lines-closed {
        position: relative;
        top: 8px;
    }
    
    .menu-lines-closed > * {
        margin: 7px auto;
    }
    
    .menu-lines-open {
        position: relative;
        top: 22px;
    }
    
    .menu-lines-open > * {
        margin: auto;
    }
    
    .menu-lines-open .menu-line-1 {
        position: relative;
        top: 2px;
        transform: rotate(45deg);
        
    }
    .menu-lines-open .menu-line-2 {
        transform: rotate(-45deg);
    }

    .scrolled {
        width: 100%;
        height: 80px;
        transition: 200ms;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    }

    .scrolled .navbar-links {
        right: 40px;
        height: 80px;
        transition: 200ms;
    }

    .phone-ctr {
        opacity: 0.6;
        position: fixed;
        height: 80px;
        top: 40px;
        left: 40px;
        transition: top 200ms;
    }

    .scrolled .phone-ctr {
        top: 0px;
        transition: top 200ms;
    }

    .phone-ctr:hover {
        cursor: pointer;
        opacity: 1;
    }

    .phone-ctr h5 {
        font-size: 16px;
        position: relative;
        padding: 0px 20px 0px 10px;
        top: -22px;
    }

    .phone-ctr img {
        width: 40px;
        position: relative;
        top: 16px;
        margin-right: 10px;
    }
}

@media (min-width: 1600px) {
    .navbar-item {
        padding: 15px;
        margin: 0px 30px;
    }
}