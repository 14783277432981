.logo-ctr {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 5px auto;
    height: 60px;
    width: 240px;
    color: #111;
    text-align: center;
    transition: 200ms;
}

.logo-ctr h1 {
    letter-spacing: 0.75px;
    font-size: 18.5px;
    margin: 0px auto;
    font-weight: 300;
    transition: 200ms;
}
.logo-ctr h2 {
    font-size: 9px;
    margin: 0px;
    letter-spacing: 0.25px;
    font-weight: 100;
    transition: 200ms;
}
.logo-ctr h4 {
    font-size: 7.5px;
    font-family: 'Times New Roman', Georgia, Times, serif;
    font-style: italic;
    margin: 16px 0px 0px;
    letter-spacing: 0.15px;
    color: #2B5695;
    transition: 200ms;
}

@media (min-width: 400px) {
    .logo-ctr {
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0%);
        margin: 5px auto;
        height: 80px;
        width: 240px;
        color: #111;
        text-align: center;
        transition: 200ms;
    }
    
    .logo-ctr h1 {
        letter-spacing: 0.75px;
        font-size: 22px;
        margin: 0px auto;
        font-weight: 300;
        transition: 200ms;
    }
    .logo-ctr h2 {
        font-size: 12px;
        margin: 0px;
        letter-spacing: 0.25px;
        font-weight: 100;
        transition: 200ms;
    }
    .logo-ctr h4 {
        font-size: 10px;
        font-family: 'Times New Roman', Georgia, Times, serif;
        font-style: italic;
        margin: 20px 0px 0px;
        letter-spacing: 0.25px;
        color: #2B5695;
        transition: 200ms;
    }
}

@media (min-width: 1200px) {
    .logo-ctr {
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0%);
        margin: 4px auto;
        height: 140px;
        width: 500px;
        color: #111;
        text-align: center;
    }
    
    .logo-ctr h1 {
        letter-spacing: 1px;
        font-size: 44px;
        margin: 0px auto;
        font-weight: 300;
        transition: 200ms;
    }
    .logo-ctr h2 {
        font-size: 20px;
        margin: 0px;
        letter-spacing: 2.4px;
        font-weight: 100;
        transition: 200ms;
    }
    .logo-ctr h4 {
        font-size: 16px;
        font-family: 'Times New Roman', Georgia, Times, serif;
        font-style: italic;
        margin: 30px 0px 0px;
        letter-spacing: 0.75px;
        color: #2B5695;
        transition: 200ms;
    }

    .logo-ctr.scrolled {
        box-shadow: none;
    }

    .scrolled h1 {
        letter-spacing: 1px;
        font-size: 26px;
        margin: 0px auto;
        font-weight: 300;
    }
    .scrolled h2 {
        font-size: 11px;
        margin: 0px;
        letter-spacing: 2px;
        font-weight: 100;
    }
    .scrolled h4 {
        font-size: 10px;
        font-family: 'Times New Roman', Georgia, Times, serif;
        font-style: italic;
        margin: 7px 0px 0px;
        letter-spacing: 0.75px;
        color: #2B5695;
    }
}