.sides-ctr {
    position: relative;
    width: 100%;
    height: 820px;
    background: #fcfcfc;
}

.left-ctr, .right-ctr {
    height: 100%;
    display: block;
}

.left-ctr {
    width: 100%;
    height: 400px;
}

.right-ctr {
    width: 100%;
}

.loc-ctr {
    position: absolute;
    margin: 50px auto 0px;
    width: 100%;
    height: 80%;
}

.loc-ctr h2 {
    position: relative;
    top: 0px;
    margin: 0px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
}

.loc-sect h4 {
    font-weight: 400;
}

.loc-sect {
    position: relative;
    width: 80%;
    /* left: 50%;
    transform: translate(-50%, 0%); */
    margin: 20px auto;
}

.loc-left, .loc-right {
    display: inline-block;
}

.loc-left {
    width: 42%;
}
.loc-right {
    position: relative;
    top: 21px;
    width: 58%;
}

.loc-left h4 {
    font-size: 14px;
}

.loc-right h4 {
    display: block;
    line-height: 25px;
    font-size: 11px;
}

.loc-sect h4 {
    margin: 0px;
}

.hours {
    position: relative;
    bottom: 42px;
}

@media (min-width: 800px) {
    .sides-ctr {
        position: relative;
        width: 100%;
        height: 700px;
    
        background: #fcfcfc;
    }
    
    .left-ctr, .right-ctr {
        height: 100%;
        display: inline-block;
    }
    
    .left-ctr {
        width: 60%;
    }
    
    .right-ctr {
        width: 40%;
    }
    
    .loc-ctr {
        position: absolute;
        margin: 100px auto 0px;
        width: 40%;
        height: 80%;
    }
    
    .loc-ctr h2 {
        position: relative;
        top: 0px;
        margin: 0px;
        text-align: center;
        width: 100%;
        font-weight: 400;
        font-size: 32px;
    }
    
    .loc-sect h4 {
        font-weight: 400;
    }
    
    .loc-sect {
        position: relative;
        width: 60%;
        max-width: 350px;
        /* left: 50%;
        transform: translate(-50%, 0%); */
        margin: 60px auto;
    }
    
    .loc-left, .loc-right {
        display: inline-block;
    }
    
    .loc-left {
        width: 52%;
    }
    .loc-right {
        position: relative;
        top: 21px;
        width: 48%;
    }
    
    .loc-right h4 {
        display: block;
        line-height: 25px;
    }
    
    .loc-sect h4 {
        margin: 0px;
    }
    
    .hours {
        position: relative;
        bottom: 42px;
    }

    .loc-left h4 {
        font-size: 14px;
    }
    
    .loc-right h4 {
        display: block;
        line-height: 25px;
        font-size: 12px;
    }
}