.foot-ctr {
    position: absolute;
    background: #031022;
    height: 130px;
    width: 100%;
}

.foot-text {
    position: relative;
    display: inline-block;
    height: 90px;
    padding: 16px;
    margin-right: auto;
}

.foot-text h6 {
    color: white;
    margin: 0px;
    font-size: 9px;
    font-weight: 400;
    line-height: 30px;
}

.foot-fb {
    position: absolute;
    bottom: 50px;
    right: 20px;
    height: 45px;
    width: 45px;
}

.foot-fb img {
    height: 100%;
    width: 100%;
}

@media (min-width: 400px) {
    .foot-ctr {
        height: 130px;
    }

    .foot-fb {
        position: absolute;
        bottom: 36px;
        right: 20px;
        height: 60px;
        width: 60px;
    }
}

@media (min-width: 1200px) {
    .foot-ctr {
        position: absolute;
        background: #031022;
        height: 220px;
        width: 100%;
    }
    
    .foot-text {
        position: relative;
        display: inline-block;
        height: 90px;
        padding: 60px 120px;
    }
    
    .foot-text h6 {
        color: white;
        margin: 0px;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
    }
    
    .foot-fb {
        position: absolute;
        bottom: 72px;
        right: 120px;
        height: 90px;
        width: 90px;
    }
    
    .foot-fb img {
        height: 100%;
        width: 100%;
    }
}