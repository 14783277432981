.hero-ctr {
    position: relative;
}

.bg-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 450px;
    pointer-events: none;
    /* padding-top: 160px; */
    background: rgba(0, 0, 0, 0.6);
}

.hero-img {
    background-position: top;
    background-attachment: scroll;
    background-size: cover;
    width: 100%;
    height: 450px;
    overflow: hidden;
}
.home-img {
    background-image: url(../images/hero.png);
}
.practice-img {
    background-image: url(../images/hero-1.png);
}
.forms-img {
    background-image: url(../images/hero-4.png);
}

.hero-text {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -25%);
    color: white;
    text-shadow: 0px 2px 4px black;
    text-align: center;
}

.hero-text h1 {
    font-size: 28px;
    width: 95vw;
    margin: 0px;
}

.hero-text h4 {
    font-size: 12px;
    font-weight: 200;
    margin: 10px auto 50px;
    letter-spacing: 3px;
}

.hero-btn {
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background: #2B5695;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

.hero-btn:hover {
    cursor: pointer;
    background: #3464ac;
}

@media (min-width: 1200px) {
    .bg-overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 800px;
        /* padding-top: 160px; */
        background: rgba(0, 0, 0, 0.6);
    }

    .hero-img {
        background-position: top;
        background-attachment: fixed;
        background-size: cover;
        width: 100%;
        height: 800px;
        overflow: hidden;
    }

    .hero-text {
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-shadow: 0px 2px 4px black;
        text-align: center;
    }

    .hero-text h1 {
        font-size: 64px;
        margin: 0px;
    }

    .hero-text h4 {
        font-size: 26px;
        font-weight: 200;
        margin: 10px auto 50px;
        letter-spacing: 6px;
    }

    .hero-btn {
        font-size: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 6px;
        background: #2B5695;
        color: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    }

    .hero-btn:hover {
        cursor: pointer;
        background: #3464ac;
    }
}
